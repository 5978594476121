
import React,{ useCallback,useState,useRef, useEffect } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import TextField from '../fields/text/index';
import { latestClients  as latestClientsStore} from '../../../localForage/index';
import AuthHelper from '../../../auth/AuthHelper';
import { setClientNavigation } from '../../../redux/actions/navigation/index';
import './ClientList.scss';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames'
import moment from 'moment';
/**
 * DESCRIPTION: used in the portal layout, it's only visible when you're a member of the incofin team.
 * It will display a list of clients (name) you have access to. It will show a maximum of 5 clients.
 * Clicking on a client will make sure that this client shows on top of this list (you will also be routed to the dashboard of this client).
 * Stored in localForage, it runs across sessions. Searching, 2 links to the incofin dashboard and to the list of clients is also included.
 */


function mapStateToProps(state) {
    return {
        language: state.session.language,
        client: state.client,
        clients:state.session.clients
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigate:(url)=> {
            
        },
		setClientNavigation:(clientId)=> {
        	dispatch(setClientNavigation(clientId));
		}
    };
}

const numberOfClientsToShow = 5;

const Menu = ({title})=> {
	const [open,setOpen] = useState(false);
	const timerRef = useRef(null);

	const clearTimer = ()=> {
		if(timerRef.current) {
			clearTimeout(timerRef.current)
			
		}
		timerRef.current=null;
	}

	const toggleMenu = useCallback(()=> {
		clearTimer();	
		setOpen(!open);
	},[open]);

	const closeMenu = useCallback(()=> {
		clearTimer();	
		setOpen(false);
	},[open]);

	const onMouseEnter = () => {
		clearTimer();
	}
	
	const onMouseLeave =  useCallback(() => {
		if(timerRef.current) {
			clearTimeout(timerRef.current);
		}
		clearTimer();
        timerRef.current =setTimeout(()=> {
            setOpen(false);
			clearTimer();
        },500);
	} ,[open]);

	const mainRef = useRef();

	const cls = classNames("multi-client-container", {"open":open});

	return <div className={cls} ref={mainRef}
	onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
	>
		<span onClick={toggleMenu}>{title}</span>
		<div className="multi-client-options" >
			{ open ? <Clients closeMenu={closeMenu}/> : <div>no clients</div> }
			{(AuthHelper.isGranted('level','INCOFIN')) ? <AdminList closeMenu={closeMenu}/> : null }
		</div>
	</div>
}

const AdminList = ({closeMenu})=> {

	const history = useHistory();
	const navigateTo = useCallback((path)=>{
		closeMenu();
		history.push(path);
	},[history,closeMenu]);

	return <div>
			<hr/>
			<h3 onClick={()=>navigateTo('/incofin/')}>
				<FormattedMessage id="header.client-list.my-dashboard"/>
			</h3>
			<h3 onClick={()=>navigateTo('/incofin/clients')}>
				<FormattedMessage id="header.client-list.more"/>
			</h3>
		</div>;
}

const ClientList  = ({client,clients,defaultTitle})=> {

	const history = useHistory();
	const navigate = useCallback((path)=> {
		history.push(path);
	},[history])

 	if(AuthHelper.isGranted('level','INCOFIN') || (clients && clients.length>1)) {

		return (
			
			<Menu title={<h2
			className="current">{client && client._id ? client.importedId ? `${client.name} (TM1:${client.importedId})`  : client.clientType ? `${client.name} (${client.clientType})` : client.name  : defaultTitle}</h2>}
				/>

		);
	} else {
		return <h2 className="current">{client && client._id ?  client.importedId ? `${client.name} (TM1:${client.importedId})`  : client.name  : defaultTitle}</h2>;
	}
}


const DefaultClients = ({clients,navTs,onClientClick})=> {
	const [defaultClients,setDefaultClients] = useState([]);

	useEffect(()=> {
		console.log("DefaultClients","INIT USE EFFECT");
		(async function () {
			console.log("DefaultClients","GET DATA");
			setDefaultClients(clients.slice(0,numberOfClientsToShow));
			/*
			try {
				debugger;
				let all = await latestClientsStore.getAll();
				console.log("DefaultClients","GOT LATEST DATA",all);
				const latestClients = all.sort((a,b)=>a.updatedAt<b.updatedAt).slice(0,numberOfClientsToShow);
				const _defaultClients = [...latestClients,
					...(clients.slice(0,numberOfClientsToShow*2).filter(c=>latestClients.findIndex(l=>l._id === c._id)===-1)
					)
				].slice(0,numberOfClientsToShow);
				console.log("DefaultClients",_defaultClients,latestClients)
				setDefaultClients(_defaultClients)
			} catch ( err ) {
				console.log("DefaultClients err",err)
				setDefaultClients(clients.slice(0,numberOfClientsToShow));
			}*/
		})();
	},[navTs,clients]);

	return <ClientMenuList clients={defaultClients} onClientClick={onClientClick}/>
}

function _filterClients(clients,filter) {
	if(!filter) return clients;
	return clients
			.filter((client)=>client.name.includes(filter) || client.name.toLowerCase().includes(filter) || client.officialName.includes(filter) || client.officialName.toLowerCase().includes(filter))
			.slice(0, numberOfClientsToShow );
}

const Clients = connect(mapStateToProps)(({clients =[],closeMenu})=> {
	const intl = useIntl();
	const history = useHistory();
	const [filter,setFilter] = useState(null);
	const [navTs,setNavTs] = useState(moment().unix());
	const [filteredClients,setFilteredClients] = useState(clients ? clients.slice(0,numberOfClientsToShow) : [])
	const filterTimerRef = useRef();

	const onClientClick= useCallback((client)=> {
		closeMenu();
		setNavTs(moment().unix());
		history.push(`/client/${client.key}`);
	},[navTs,history,closeMenu]);

	const applyFilter = useCallback(()=>{
		setFilteredClients(_filterClients(clients,filter).slice(0,numberOfClientsToShow));
	},[filter,clients])

	const onFilterChange = useCallback((textFieldInstance, value, previousValue) => {
		console.log('FILTER',value,previousValue)
		if(filterTimerRef.current) {
			clearTimeout(filterTimerRef.current);
		}
		filterTimerRef.current=null;
		setFilter(value);
		
        filterTimerRef.current = setTimeout(()=> {
        	applyFilter();
			filterTimerRef.current=null;
		},100);
	},[filter]);

	// clear filter on unmount
	useEffect(() => {
		return ()=> {
			setFilter(null);
		}
	},[])


	return <>
		<TextField placeholder={intl.formatMessage({id: 'header.client-list.search-placeholder'})}
									 readOnly={false} type={'text'} onLiveChange={onFilterChange} />
		{ (filter!=null) ? <ClientMenuList clients={filteredClients} onClientClick={onClientClick}/>
			 : <DefaultClients clients={clients} navTs={navTs} onClientClick={onClientClick}/>
			}
	</>

});

const ClientMenuList = ({clients,onClientClick})=> {

	

	return  clients.map((client,idx)=><h3 key={idx} onClick={()=>onClientClick(client)}>{
		client.officialName != client.name ? <>{client.officialName} ({client.name})</> : <>{client.name}</>
}</h3>);
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
//export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(_ClientList));
