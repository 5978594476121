import React from 'react';
import {connect} from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import Loader from '../loader/index.jsx';
import Spinner from '../loader/spinner';
import SelectField from '../fields/select/index';
import CurrencySelect from '../fields/select/currency';
import SelectClientStatus from '../fields/select/client-status';
import AuthHelper from '../../../auth/AuthHelper';
import countryQuery from '../../../graph/queries/countryQuery';
import fundsQuery from '../../../graph/queries/incofin/funds';
import ClientsGridSelector from '../clientsGridSelector/index';
import {createExport} from '../../../graph/mutations/export';
import Timeline from '../../../features/TimeSlicer/index';
import RequiredLabel from '../RequiredLabel/index';
import _ from 'underscore';

function mapStateToProps(state) {
	return {
		clients: state.session.clients,
		regions: state.session.regions,
		client: state.client
	};
}

// issue-42
// const statusOptions = [
// 	{label: 'Created', value: 'created'},
// 	{label: 'In Progress', value: 'in_progress'},
// 	{label: 'In Review', value: 'in_review'},
// 	{label: 'Submitted', value: 'submitted'},
// 	{label: 'Rejected', value: 'rejected'},
// 	{label: 'Approved', value: 'approved'},
// 	// {label: 'Overdue', value: 'overdue'}
// ];
const statusOptions = [
	{label: 'Active', value: 'active'},
	{label: 'In Active', value: 'inactive'},
	{label: 'Removed', value: 'removed'},
	{label: 'Approved', value: 'approved'},
];
// issue-42
const overdueOptions = [
	{label: 'Yes', value: 'yes'},
	{label: 'No', value: 'no'},
];

class ExportForm extends React.Component {
	constructor(props) {
		super(props);

		this.isIncofin = AuthHelper.isGranted('level', 'INCOFIN');
		this.isClient = AuthHelper.isGranted('level', 'CLIENT') && !this.isIncofin;

		this.state = {
			generating: false,
			exportType: "CLIENT",
			clientType: this.props.client ? this.props.client.clientType : "MFI",
			chosenRegions: [],
			countryList: [],
			chosenCountries: [],
			chosenClients: this.isClient || (this.props.client && this.props.client._id) ? [this.props.client._id] : [],
			currency: [],
			listingType: "Colums per client",
			clients: this.filterClients(props.clients, ['active', 'workout'],this.props.client ? this.props.client.clientType : "MFI"),
			selectedClients: [],
			unselectedClients: [],
			funds: [],
			clientStatus: ['active', 'workout'],
			summaryType: "",
      reportStatus: [], // issue-42
      overdue: null,
		};

		this.exportTypes = (this.isIncofin && (this.props.client && !this.props.client._id)) ? ["CLIENT", "REGION/COUNTRY", "FUNDS"] : ["CLIENT"];
		this.listingTypes = [/*"Colums per region", "Colums per country", */"Colums per client"];   // temporary only "Colums per client"
		this.summaryTypes = ["SUM", "AVG"];

		this.onClickTypeButton = this.onClickTypeButton.bind(this);
		this.onClientChange = this.onClientChange.bind(this);
		this.onClientTypeChange = this.onClientTypeChange.bind(this);
		this.onSelectedClientRowClick = this.onSelectedClientRowClick.bind(this);
		this.onUnselectedClientRowClick = this.onUnselectedClientRowClick.bind(this);
		this.onRegionChange = this.onRegionChange.bind(this);
		this.onCountryChange = this.onCountryChange.bind(this);
		this.onCurrencyChange = this.onCurrencyChange.bind(this);
		this.onFundsChange = this.onFundsChange.bind(this);
		this.onClientStatusChange = this.onClientStatusChange.bind(this);
		this.onReportStatusChange = this.onReportStatusChange.bind(this);
		this.createExport = this.createExport.bind(this);
		this.onOverdueChange = this.onOverdueChange.bind(this);
	}

	filterClients(clients, status,type) {
		return clients.filter((client) => {
			 
			return ((type=="AGRO" && client.clientType=="AGRO") || (type!="AGRO" && client.clientType!="AGRO")) &&  status.indexOf(client.status) > -1;
		});
	}

	componentWillReceiveProps(props) {
		this.isClient = AuthHelper.isGranted('level', 'CLIENT');
		this.isIncofin = AuthHelper.isGranted('level', 'INCOFIN');
		this.isClient = this.isClient && !this.isIncofin;
	}

	onSelectedClientRowClick(val) {
		const {
			selectedClients,
			unselectedClients
		} = this.state;

		let clientIdx = selectedClients.findIndex((c) => c._id === val._id); //  _.findWhere(this.state.selectedClients, {key: val.key});
		if (clientIdx > -1) {
			this.setState(() => {
				return {
					selectedClients: Array.from(new Set([...selectedClients.slice(0, clientIdx), ...selectedClients.slice(clientIdx + 1)])),
					unselectedClients: Array.from(new Set([...unselectedClients, selectedClients[clientIdx]])).sort((s, t) => s.officialName.toUpperCase() !== t.officialName.toUpperCase() ? s.officialName.toUpperCase() > t.officialName.toUpperCase() ? 1 : -1 : 0)
				}
			});
		}
	}

	onUnselectedClientRowClick(val) {
		const {
			selectedClients,
			unselectedClients
		} = this.state;

		let clientIdx = unselectedClients.findIndex((c) => c._id === val._id); //  _.findWhere(this.state.selectedClients, {key: val.key});
		if (clientIdx > -1) {
			this.setState(() => {
				return {
					unselectedClients: Array.from(new Set([...unselectedClients.slice(0, clientIdx), ...unselectedClients.slice(clientIdx + 1)])),
					selectedClients: Array.from(new Set([...selectedClients, unselectedClients[clientIdx]])).sort((s, t) => s.officialName.toUpperCase() !== t.officialName ? s.officialName.toUpperCase() > t.officialName.toUpperCase() ? 1 : -1 : 0)
				}
			});
		}
	}

	onRegionChange(field, val, previousValue) {
		if (!val) {
			return;
		}

		const {
			regions,
			countryQuery: data
		} = this.props;

		let regionValues = val;

		let countries = regions.reduce((arr, r) => {
			if (regionValues.some((rv) => rv.value === r._id)) {
				arr = [...arr, ...r.countries];
			}
			return arr;
		}, []);

		if (this.state.chosenCountries && this.state.chosenCountries.length > 0) {
			countries = [...countries, this.state.chosenCountries];
		}
		countries = [...(new Set(countries))]; // distinct values
		countries = data.countries.reduce((arr, c) => {
			if (countries.indexOf(c._id) > -1) {
				arr.push({value: c._id, label: c.name});
			}
			return arr;
		}, []);

		this.setState(() => {
			return {
				chosenRegions: regionValues,
				countryList: countries
			};
		});
		this.onCountryChange(null, countries);
	}

	onCountryChange(field, countries, previousValue) {
		let values = _.pluck(countries, 'value');
		this.setState(() => {
			return {
				chosenCountries: values
			};
		});

		this.calculateClients(values, this.props.clients, this.state.clientStatus);
	}

	onClientTypeChange(field, val) {
		let filteredClients = this.filterClients(this.props.clients, this.state.clientStatus,val);

		this.setState(() => ({
			clientType: val,
			clients: filteredClients
		}));

		this.calculateClients(this.state.chosenCountries, filteredClients, this.state.clientStatus);
		
	
	}

  // issue-42
  onOverdueChange(field, val) {
		this.setState(() => ({
			overdue: val
		}));
	}

	onClientChange(field, val) {
		this.setState(() => ({
			chosenClients:  _.pluck(val, 'value')
		}));
	}

	onCurrencyChange(field, val) {
		debugger;
		this.setState(() => ({
			currency: _.pluck(val, 'value')
		}));
	}

	onFundsChange(field, val) {
		this.setState(() => ({
			funds: _.pluck(val, 'value')
		}));
	}

	onClientStatusChange(field, val) {
		let values = val ? val : [];
		let filteredClients = this.filterClients(this.props.clients, values,this.state.clientType);

		this.setState(() => ({
			clientStatus: values,
			clients: filteredClients
		}));

		this.calculateClients(this.state.chosenCountries, filteredClients, values);
	}
  onReportStatusChange(field, val) {
    this.setState(() => ({
			reportStatus: _.pluck(val, 'value'),
		}));
	}

	onClickTypeButton(val) {
		this.setState(() => ({
			exportType: val
		}));
	}

	onClickListingButton(val) {
		this.setState(() => ({
			listingType: val
		}));
	}

	onClickSummaryButton(val) {
		this.setState(() => ({
			summaryType: val
		}));
	}

	calculateClients(countryList, clients, clientStatus) {
		let selectedClients = clients.filter((c) => c.address && countryList.indexOf(c.address.country) > -1 && clientStatus.indexOf(c.status) > -1);
		let unselectedClients = Array.from(new Set([...(this.state.unselectedClients ? this.state.unselectedClients : [])]));
		if (unselectedClients.length > 0) {
			selectedClients = selectedClients.filter((c) => !unselectedClients.some((uc) => uc._id === c._id));
		}

		this.setState(() => {
			return {
				unselectedClients,
				selectedClients: selectedClients.sort((s, t) => s.officialName.toUpperCase() !== t.officialName ? s.officialName.toUpperCase() > t.officialName.toUpperCase() ? 1 : -1 : 0)
			};
		});
	}

	isValid() {
		if(!this.state.clientStatus || this.state.clientStatus.length === 0) {
			return false;
		}

		if (!this.periodSelector || !this.periodSelector.getValue() || !this.periodSelector.getValue().periodType || !this.periodSelector.getValue().periods || this.periodSelector.getValue().periods.length < 1) {
			return false;
		}

		if (!this.state.currency || this.state.currency.length < 1) {
			return false;
		}

		if (this.state.exportType === "CLIENT") {
			if (!this.state.chosenClients || this.state.chosenClients.length < 1) {
				return false;
			}
		}

		if (this.state.exportType === "REGION/COUNTRY") {
			if (!this.state.chosenCountries || this.state.chosenCountries.length < 1) {
				return false;
			}
			if (!this.state.selectedClients || this.state.selectedClients.length < 1) {
				return false;
			}
		}

		if (this.state.exportType === 'FUNDS') {
			if (!this.state.funds || this.state.funds.length === 0) {
				return false;
			}
		}

		return true;
	}

	createExport() {
		if (this.isValid()) {
			let selectedPeriod = this.periodSelector.getValue();
      // feature/issue-7
			let formConfigs = ['balanceSheet', 'profitLoss', 'extras', 'extras2', 'performanceIndicators', 'cashFlow', 'infoIc'];
			if(this.state.clientType=="AGRO") {
				formConfigs = ['agroBalanceSheet', 'agroProfitLoss', 'agroRatios','agroCashflow'];
			}

			if (this.state.exportType === 'CLIENT' && (this.state.chosenClients && this.state.chosenClients.length === 1)) {
				formConfigs.unshift('identification');
			}
            if (this.state.clientType!="AGRO" && this.state.exportType === 'FUNDS') {
                formConfigs.push('ratios');
			}

			this.setState(() => {
				return {generating: true}
			});

			this.props.createExport({
				variables: {
					exportType: this.state.exportType,
					currencies: this.state.currency,
					clients: (this.state.exportType === 'CLIENT') ? this.state.chosenClients : this.state.selectedClients.map((client) => {
						return client._id
					}),
					countries: this.state.exportType === 'CLIENT' ? [] : this.state.chosenCountries,
					regions: this.state.exportType === 'CLIENT' ? [] : this.state.chosenRegions.map((region) => {
						return region.value
					}),
					periods: selectedPeriod,
					formConfigs: formConfigs,
					funds: this.state.funds,
					clientStatus: this.state.clientStatus,
          reportStatus: this.state.reportStatus, // issue-42
          overdue: this.state.overdue,
				}
			}).then(({data}) => {
				this.setState(() => {
					return {generating: false}
				});

				if (data.createExport) {
					let a = document.createElement("a");
					document.body.appendChild(a);
					a.href = "/download/" + data.createExport;
					a.click();
					document.body.removeChild(a);
				}
			}).catch(() => {
				this.setState(() => {
					return {generating: false}
				});
			});
		} else {
			this.container.error('Please fill in all the information.');
		}
	}

	renderTypeSelection() {
		return this.exportTypes.map((type, index) => {
			return <button key={index} onClick={() => this.onClickTypeButton(type)}
										 className={this.state.exportType === type ? 'selected' : null}>{type}</button>
		}, this);
	}

	renderListingSelection() {
		return this.listingTypes.map((type, index) => {
			return <button key={index} onClick={() => this.onClickListingButton(type)}
										 className={this.state.listingType === type ? 'selected' : null}>{type}</button>
		}, this);
	}

	// Not used for now
	renderSummarySelection() {
		return (this.state.exportType !== 'CLIENT' && this.state.listingType !== 'Colums per client') ?
			<div className="form-group">
				<label>Summary Type</label>
				{
					this.summaryTypes.map((type, index) => {
						return <button key={index} onClick={() => this.onClickSummaryButton(type)}
													 className={this.state.summaryType === type ? 'selected' : null}>{type}</button>
					}, this)
				}
			</div> : null
	}

	render() {
		console.log("CLIENT",this.props.client);
		if (this.props.countryQuery.loading || this.props.fundsQuery.loading) {
			return <Loader/>;
		}

		if (this.state.generating) {
			return (
				<div style={{textAlign: 'center'}}>
					<Spinner/>
					GENERATING EXPORT
				</div>
			);
		}

		const {
			fundsQuery: {
				funds
			}
		} = this.props;

		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		const clientOptions = [];
		let clientAgroOptions = [];
		let client = this.props.client ? {
			value: this.props.client._id,
			label: this.props.client.name
		} : null;

		if (this.isIncofin && this.state.clients && this.state.clients.length > 0) {
			
			this.state.clients.forEach((client) => {
				clientOptions.push({
					value: client._id,
					label: client.name
				});
			});
		} else if (this.props.client) {
			clientOptions.push({
				value: this.props.client._id,
				label: this.props.client.name
			});
		}
		
	
		const regionOptions = [];
		if (this.props.regions && this.props.regions.length > 0) {
			this.props.regions.forEach((region) => {
				regionOptions.push({
					value: region._id,
					label: region.region
				});
			});
		}

		let countryOptions = [];
		if (this.state.countryList && this.state.countryList.length > 0) {
			countryOptions = this.state.countryList;
		} else if (this.props.data && this.props.data.countries) {
			this.props.countryQuery.countries.forEach((country) => {
				countryOptions.push({
					value: country._id,
					label: country.name
				});
			});
		}

		const fundsOptions = [];
		if (funds) {
			funds.forEach((fund) => {
				fundsOptions.push({
					value: fund._id,
					label: fund.name
				});
			});
		}
		console.log("CLIENT OPTIONS", clientOptions)

		return (
			<div className="export-form" style={{marginBottom: '4em'}}>
				<ToastContainer ref={(c) => this.container = c} toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>

				<div className="required-fields-label">
					<FormattedMessage id="required-fields.label"/>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.export-type"/>
						<RequiredLabel/>
					</label>
					{this.renderTypeSelection()}
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.period"/>
						<RequiredLabel/>
					</label>
					<div style={{width: '75%'}}>
						<Timeline ref={(c) => this.periodSelector = c} from={2000} to={moment().year()}
											withOnChangeTimeout={false} filter={[]}/>
					</div>
				</div>

				{
					this.isIncofin && this.props.client && !this.props.client._id ?
						<div className="form-group">
							<label>
								<FormattedMessage id="export.labels.client-status"/>
								<RequiredLabel/>
							</label>
							<SelectClientStatus multi={true} readOnly={false} onChange={this.onClientStatusChange}
																	placeholder={'Select client status ...'} value={this.state.clientStatus}/>
						</div>
						: null
				}
        {/* issue-42 */}
        {
					this.isIncofin && this.props.client && !this.props.client._id ?
          <div className="form-group">
					<label>
						<FormattedMessage id="export.labels.report-status"/>
          </label>
          <SelectField multi={true} options={statusOptions} placeholder={'Select report status ... '} onChange={this.onReportStatusChange}
                      identifier={'reportStatus'} value={this.state.reportStatus} />
          </div>
					: null
				}
        {/* {
          this.isIncofin && this.props.client && !this.props.client._id ?
          <div className="form-group">
            <label>
              <FormattedMessage id="export.labels.overdue"/>
            </label>
            <SelectField multi={false} options={overdueOptions} placeholder={'Select overdue status ... '}
                      onChange={this.onOverdueChange}/>
				  </div>
					: null
        } */}

        {/* issue-65 */}
				{/* { this.state.exportType === 'CLIENT' &&  this.isIncofin && this.props.client && !this.props.client._id ? */}
				{ this.isIncofin && this.props.client && !this.props.client._id ?
						<div>
						<div className="form-group">
							<label>
								<FormattedMessage id="admin.form.client.clientType"/>
								<RequiredLabel/>
							</label>
							<SelectField multi={false} options={[{value: 'AGRO', label: 'AGRO'},{value: 'MFI', label: 'MFI/BANK'}]} readOnly={false}
														placeholder={'Select clienttype ... '} onChange={this.onClientTypeChange}/>
							
						</div>
					</div>
					: null
				}

				{ this.state.exportType === 'CLIENT' ?
					
					<div>
						<div className="form-group">
							<label>
								<FormattedMessage id="export.labels.clients"/>
								<RequiredLabel/>
							</label>
							{ this.isIncofin && this.props.client && !this.props.client._id ?
								<SelectField multi={true} options={clientOptions} readOnly={false}
														 placeholder={'Select client(s) ... '} onChange={this.onClientChange}/>
								:
								<SelectField multi={false} value={client} readOnly={true}
														 placeholder={'Select client(s) ... '} onChange={this.onClientChange}/>
							}
						</div>
					</div>
					: (this.state.exportType === 'REGION/COUNTRY' && this.isIncofin) ?
						<div>
							<div className="form-group">
								<label>
									<FormattedMessage id="export.labels.regions"/>
									<RequiredLabel/>
								</label>
								<SelectField multi={true} value={this.state.chosenRegions} options={regionOptions}
														 placeholder={'Select region(s) ... '} onChange={this.onRegionChange}/>
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="export.labels.countries"/>
									<RequiredLabel/>
								</label>
								<SelectField multi={true} value={this.state.chosenCountries} options={countryOptions}
														 placeholder={'Select country(s) ... '} onChange={this.onCountryChange}/>
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="export.labels.clients"/>
									<RequiredLabel/>
								</label>
								<ClientsGridSelector unselected={this.state.unselectedClients} selected={this.state.selectedClients}
																		 itemsPerPage={this.props.clients.length}
																		 onSelectedRowClick={this.onSelectedClientRowClick}
																		 onUnselectedRowClick={this.onUnselectedClientRowClick}/>
							</div>
						</div>
						: (this.state.exportType === 'FUNDS' && this.isIncofin) ?
							<div className="form-group">
								<label>
									<FormattedMessage id="export.labels.funds"/>
									<RequiredLabel/>
								</label>
								<SelectField multi={true} value={this.state.funds} options={fundsOptions}
														 placeholder={'Select fund(s) ... '} onChange={this.onFundsChange}/>
							</div>
							: null
				}

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.currencies"/>
						<RequiredLabel/>
					</label>
					<CurrencySelect multi={true} onChange={this.onCurrencyChange} placeholder={'Select currency(s) ... '}/>
				</div>

				{
					this.state.exportType !== 'CLIENT' ?
						<div className="form-group">
							<label>
								<FormattedMessage id="export.labels.listingType"/>
								<RequiredLabel/>
							</label>
							{this.renderListingSelection()}
						</div> : null
				}

				<div className="submit-button" style={{height: '3em', marginTop: '2em'}}>
					<button onClick={this.createExport}>
						<FormattedMessage id="export.labels.export-to-excel"/>
					</button>
				</div>
			</div>
		);
	}
}
export default graphql(countryQuery, {name: 'countryQuery'})(
	graphql(fundsQuery, {name: 'fundsQuery'})(
	graphql(createExport, {name: 'createExport'})(
	connect(mapStateToProps, null)(ExportForm))));